import React from 'react';
import { Text, StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
} from '../../../../globalStyles';
import { TextProps } from '../../../../utils/types';

const TextEveningActivity = (props: TextProps) => {
  const processChild = (child: string) => {
    const parts = child.split(' - ');
    let location: string | null = null;

    if (parts.length >= 1) {
      const jsonStartIndex = parts[0].indexOf('{');
      if (jsonStartIndex !== -1) {
        location = parts[0].substring(0, jsonStartIndex);
      } else {
        location = parts[0];
      }
    }

    return location ? (
      <Text key={location} style={styles.text}>
        {location}
      </Text>
    ) : null;
  };

  if (!props.children) {
    return null;
  }

  return (
    <>
      {Array.isArray(props.children)
        ? props.children.map(processChild)
        : processChild(props.children.toString())}
    </>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.smallRegular,
    color: colourConst.blue,
    textAlign: 'center',
    lineHeight: 14,
  },
});

export default TextEveningActivity;
