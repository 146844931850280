import React, { useEffect, useState } from 'react';
import { View, Image, StyleSheet, ScrollView, Text } from 'react-native';
import Gallery from 'react-photo-gallery';
import TestGallery from '../Gallery';
import { useSecureContext } from '../../context';
import { getImageUrls, getPhotoIds, fetchImageUrl } from '../../helpers/photos';
interface PhotoGalleryProps {
  route: {
    params: {
      categoryName: string;
      categoryId: string;
    };
  };
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ route }) => {
  const [photoUrls, setPhotoUrls] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const categoryName = route.params?.name;
  const categoryId = route.params?.id;
  const { token, locationAndWave } = useSecureContext();
  const waveId = locationAndWave?.wave?.ProjectId;
  useEffect(() => {
    setIsLoading(true);
    getPhotoIds(token, categoryId, waveId).then(async (data) => {
      for (const photoId of data) {
        const imageData = await fetchImageUrl(token, photoId.Id);
        const imageUrl = {
          src: imageData,
          width: photoId.IsPortrait ? 2 : 3,
          height: photoId.IsPortrait ? 3 : 2,
        };
        setPhotoUrls((prevPhotoUrls) => [...prevPhotoUrls, imageUrl]);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <ScrollView
      contentContainerStyle={isLoading ? styles.loadingContainer : {}}
      style={{ flex: 1 }}
    >
      {isLoading && photoUrls && photoUrls.length === 0 ? (
        <Image
          style={styles.loader}
          source={require('../../../assets/loader.gif')}
        />
      ) : photoUrls && Array.isArray(photoUrls) && photoUrls.length > 0 ? (
        <View style={styles.container}>
          <TestGallery data={photoUrls} />
        </View>
      ) : (
        <Text>
          A selection of the photos from your event will be posted here each
          day.
        </Text>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: '#fff' },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  loader: {
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  loadingContainer: {
    flexGrow: 1,
    justifyContent: 'center',
  },
});

export default PhotoGallery;
