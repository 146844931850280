import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Image,
  Modal,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import { colourConst, fontFamilyConst } from '../../globalStyles';
import Swiper from 'react-native-swiper/src';

const TestGallery: React.FC<TestGalleryProps> = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const renderItem = ({ item, index }) => (
    <TouchableOpacity
      style={[styles.imageContainer, { aspectRatio: item.width / item.height }]}
      onPress={() => {
        setSelectedImage({ ...item, index });
        setModalVisible(true);
      }}
    >
      <Image
        source={{ uri: item.src }}
        style={styles.image}
        resizeMode="contain"
      />
    </TouchableOpacity>
  );

  if (data && Array.isArray(data)) {
    return (
      <View style={{ flex: 1 }}>
        <FlatList
          data={data}
          renderItem={renderItem}
          keyExtractor={(item, index) => index.toString()}
          numColumns={2}
          contentContainerStyle={styles.listContainer}
        />
        <Modal
          animationType="slide"
          transparent={false}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(false);
          }}
        >
          <SafeAreaView style={styles.modalContainer}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setModalVisible(false)}
            >
              <Text style={styles.closeButtonText}>Close</Text>
            </TouchableOpacity>
            <Swiper
              loop={false}
              index={selectedImage?.index}
              showsPagination={false}
              showsButtons={true}
            >
              {data.map((item, index) => (
                <Image
                  key={index}
                  source={{ uri: item.src }}
                  style={styles.modalImage}
                  resizeMode="contain"
                />
              ))}
            </Swiper>
          </SafeAreaView>
        </Modal>
      </View>
    );
  } else {
    return null;
  }
};
export default TestGallery;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  listContainer: {
    paddingHorizontal: 8,
    paddingBottom: 16,
  },
  imageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 8,
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#f0f0f0',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'black',
  },
  closeButton: {
    position: 'absolute',
    top: 56,
    right: 30,
    zIndex: 1,
  },
  closeButtonText: {
    color: colourConst.inactiveGray,
    fontFamily: fontFamilyConst.regular,
    fontSize: 18,
    textShadowColor: 'black',
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 1,
  },
  modalImage: {
    width: '100%',
    height: '100%',
  },
});
